import { Datarow } from "./Datarow"
export const DatarowContainer = ({ datarow, handleData, dataOnBlur }) => {


    return(
        <div className="modal" id="datarowcontainer" tabIndex="-1" aria-labelledby="datarowcontainer" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                <div className="modal-header">
                    <h1 className="modal-title fs-5" id="exampleModalLabel">Modal title</h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body text-black">

                <Datarow handleData={handleData} datarow={datarow} dataOnBlur={dataOnBlur} />  

                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" className="btn btn-primary">Save changes</button>
                </div>
                </div>
            </div>
        </div>
    )
}